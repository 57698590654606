//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountryCard from "./CountryCard.vue";
import CurrentCountry from "./CurrentCountry.vue";
import CountriesSelect from "./CountriesSelect.vue";

export default {
  props: {
    country: {
      type: Object,
      default: () => {
        return {}
      }
    },

    service: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDisabledBuy: {
      type: Boolean,
      default: false
    },
    isLoadingCurrentCountry: {
      type: Boolean,
      default: false
    },

    countries: {
      type: Array,
      default: () => {
        return []
      }
    },
    favoriteCountries: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  computed: {
    currentCountry() {
      return currentCountry
    },
    serviceIcon: function () {
      return String(this.service.icon_id || 46917)
    },
    serviceName: function () {
      return this.service?.name || "&nbsp;"
    }
  },

  components: {
    CountryCard,
    CurrentCountry,
    CountriesSelect
  },

  methods: {
    buyNumber: function (country, type) {
      this.$emit('buyNumber', country, type);
    },
    changeCountry: function (country) {
      this.$emit('changeCountry', country);
    },
  }
}

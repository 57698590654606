//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    country: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isOnlyApi: {
      type: Boolean,
      default: false
    },
    isDisabledBuy: {
      type: Boolean,
      default: false
    },
    isCountryRussia: {
      type: Boolean,
      default: false
    },
    isLoadingCurrentCountry: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    countryIcon: function () {
      const icon = this.country?.icon_id;
      if (!icon) {
        return ""
      }
      return String(icon);
    },
    countryName: function () {
      return this.country?.name || ""
    },

    countLabel: function () {
      const count = Number.parseFloat(this.country?.count || '0');
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }
      return this.$numberFormat(this.country?.count, 0, '')
    },
    priceLabel: function () {
      let price = this.$convertCurrency(this.country?.price);
      price = this.$numberFormat(Number.parseFloat(price), 2, '.');

      let currency = this.$store.getters["currency/currentCurrency"];
      currency = Boolean(currency === 'rub') ? '₽' : '$';

      return [price, currency].join(' ')
    },

    isTemporarilyUnavailable: function () {
      if (this.isLoadingCurrentCountry) {
        return false
      }
      return Boolean(Object.keys(this.country || {}).length <= 0)
    },
  },

  methods: {
    buy: function () {
      if (this.isOnlyApi || this.isCountryRussia) {
        return
      }
      this.$emit('buy', this.country)
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CountriesSelect',

  data: function () {
    return {
      search: [],
      isOpen: false
    }
  },

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    country: {
      type: Object,
      default: () => {
        return {}
      }
    },
    placeholder: {
      type: String,
      default: ""
    },
    isDisabledBuy: {
      type: Boolean,
      default: false
    },
    isCountryRussia: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    optionsSearch: function () {
      const search = String(this.search || "").toLowerCase();
      if (search.length <= 2) {
        return this.options
      }
      return [...(this.options || [])]
        .filter((t) => {
          const tSlug = String(t?.country_slug || '').toLowerCase();
          const tName = String(t?.name || '').toLowerCase();
          return Boolean(tSlug.includes(search) || tName.includes(search));
        })
    },

    countLabel: function () {
      const count = Number.parseFloat(this.country?.count || '0');
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return [
        this.$numberFormat(this.country?.count, 0, ''),
        this.$t('servicesList.quantity')
      ].join('<br/>');
    },
    priceLabel: function () {
      if (Object.keys(this.country || {}).length <= 0) {
        return ""
      }

      let price = this.$convertCurrency(this.country?.price);
      price = this.$numberFormat(Number.parseFloat(price), 2, '.');

      let currency = this.$store.getters["currency/currentCurrency"];
      currency = Boolean(currency === 'rub') ? '₽' : '$';

      return [price, currency].join(' ')
    }
  },

  watch: {
    isOpen: function () {
      if (this.isOpen) {
        window.addEventListener('click', this.eventClick, true);
      } else {
        window.removeEventListener('click', this.eventClick, true);
      }
    }
  },

  methods: {
    eventClick: function (event) {
      if (event.target.closest('.service-countries-select')) {
        return
      }
      this.changeOpen();
    },

    changeOpen: function () {
      if (this.isCountryRussia) {
        return
      }
      this.isOpen = !this.isOpen;
    },
    changeCountry: function (country) {
      this.$emit("change", country);
      this.isOpen = false;
    },

    buyNumber: function () {
      if (this.isDisabledBuy) {
        return
      }
      this.$emit("buyNumber", this.country)
    },

    _getCountOption: function (val) {
      const count = Number.parseFloat(val || '0');
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return [
        this.$numberFormat(count, 0, ''),
        this.$t('servicesList.quantity')
      ].join('<br/>');
    },
    _getPriceOption: function (val) {
      let price = this.$convertCurrency(val);
      price = this.$numberFormat(Number.parseFloat(price), 2, '.');

      let currency = this.$store.getters["currency/currentCurrency"];
      currency = Boolean(currency === 'rub') ? '₽' : '$';

      return [price, currency].join(' ')
    },
  }
}

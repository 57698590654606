//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {sortArray} from "~/helpers/arraySorts.js";
import {UserMixin} from "../node_modules/sitis-nuxt/lib/mixins";
import ssrReplaceText from "~/helpers/ssrReplaceText";
import YandexMetricaMixin from "~/mixin/YandexMetricaMixin";
import Breadcrumbs from "../components/page/PageBreadcrumbs.vue";
import getSelectedCountryId from "~/helpers/getSelectedCountryId";
import LanguagesList from "~/components/profile/LanguagesList.vue";
import {checkCmsPageFromPath} from "~/helpers/pageIndexHelper";
import utilsGetOgUrl from "~/helpers/utilsGetOgUrl";
import TagsArticles from "~/components/service/TagsArticles.vue";
import MainSection from "~/components/page-service/MainSection.vue";
import BannerForBuyAccounts from "~/components/page/BannerForBuyAccounts.vue";

export default {
  name: "MenuCmsPage",
  layout: "custom-layout",

  head: function () {
    if (this.service) {
      const service = this.service;
      const locale = this.$i18n?.locale || 'en';
      const seoDescription = ssrReplaceText(String(this.$store.state.appState?.vars?.[`${ locale }_Страница сервиса_meta description`] || ""), {
        search: "{name}",
        replace: service?.name
      })

      const head = {
        title: service?.seo_title || "Grizzly SMS",
        meta: [
          {
            hid: "metadescription",
            name: "description",
            content: service?.seo_description || seoDescription || "",
          },
          {name: "keywords", content: service?.seo_keywords || ""},
          {
            hid: "og:title",
            name: "og:title",
            property: "og:title",
            content: service?.seo_title || "Grizzly SMS",
          },
          {
            hid: "og:site_name",
            name: "og:site_name",
            property: "og:site_name",
            content: "GrizzlySMS",
          },
          {
            hid: "og:description",
            name: "og:description",
            property: "og:description",
            content: service?.seo_description || seoDescription || "",
          },
          {
            hid: "apple-mobile-web-app-title",
            name: "apple-mobile-web-app-title",
            content: `apple-${service?.seo_title || ""} `,
          },
          {
            hid: "og:image",
            name: "og:image",
            property: "og:image",
            content: service?.icon || 'logo',
          },
          {
            hid: "og:url",
            name: "og:url",
            property: "og:url",
            content: utilsGetOgUrl({ context: this }),
          },
        ],
      };

      return head;
    }
    return {};
  },

  mixins: [
    UserMixin,
    YandexMetricaMixin
  ],

  data: function () {
    return {
      isPageReload: false,
      isLoading: false,
      currentNumbers: [],
      countrysList: [],
      localeServicesCountry: [],
      currentCountry: {
        external_id: "",
        name: "",
        icon: "",
      },
      isDisabledBuy: false,
      service: null,
      isLoadingCurrentCountry: true
    };
  },

  async asyncData({$api, $cookies, route, params, store, i18n}) {
    const apiMenus = store?.state?.cms?.menus || {};

    const locale = i18n?.locale || i18n?.defaultLocale;
    let fullPath = route.path === '/' ? '' : route.path;
    fullPath = String(fullPath || '').split(`/${ locale }`).pop() || '';

    let typeCurrentPage = "service";
    if (checkCmsPageFromPath(fullPath || '', apiMenus)) {
      typeCurrentPage = "cms";
    }

    let resService = null;
    let slug = params.pathMatch;
    let isCountresLoaded = false;
    if (typeCurrentPage === 'service') {
      resService = await $api.agent.transport
        .get(`service/get-by-slug/${slug}?expand=accountAvailable,tagsArticles`)
        .catch((err) => {});
    }

    let service = resService?.data || null;

    // Инициалиация выбранной страны на серверной части
    const searchCountry = route?.query?.country || "";
    const cookiesSelectedCountryId = $cookies.get('selectedCountryId');
    const countries = store?.state?.['languages-list']?.languages || [];
    let country = null;
    // if (!!searchCountry) {
    //   country = countries.find((t) => Boolean(String(t.country_slug || '').toLowerCase() === String(searchCountry || '').toLowerCase()));
    // }
    // if (!country && !!String(cookiesSelectedCountryId)) {
    //   country = countries.find((t) => Boolean(String(t.country_external_id || '') === String(cookiesSelectedCountryId)));
    // }

    return {
      service,
      currentCountry: country,
      favoriteCountries: (service?.favorite_countries || [])
    };
  },

  components: {
    MainSection,
    Breadcrumbs,
    TagsArticles,
    LanguagesList,
    BannerForBuyAccounts,
  },

  computed: {
    path() {
      return this.$route.path === "/" ? "homepage" : this.$route.path;
    },
    user() {
      return this.$store.getters['user/user'] || null;
    },
    getBreadcrumbs() {
      const breadcrumbs = [
        {path: "/", title: this.$t("breadcrumbs.homePage")},
        {path: this.$route.path, title: this?.service?.name || ""},
      ];
      return breadcrumbs;
    },
    getCurrentServiceInfo() {
      let serviceList = this.localeServicesCountry || this.$store?.state?.services?.services || [];
      let currentService = serviceList.find((t) => t.service_external_id == this.service.external_id);
      return [currentService];
    },

    countrySearch: function () {
      return this.$route?.query?.country || ""
    },
    isMobileTemplate: function () {
      return Boolean(this.$store.state["local-settings"]?.viewport === 'mobile');
    },
    isVisibleTagsArticles: function () {
      return (this.service?.tagsArticles || []).length > 0
    },

    isLoadingUser: function () {
      return this.$store.state?.user?.isLoadingUser
    }
  },

  watch: {
    service: async function () {
      if (Object.keys(this.service || {}).length > 0) {
        await this.getCountrysList();
        await this.initCurrentCountry();
        document.getElementById("scroll-container").scrollTo({
          top: 0,
          behavior: "instant",
        });
        await this.getPricesFavoriteCountries();
      }
    },
    countrySearch: async function () {
      document.getElementById("scroll-container").scrollTo({
        top: 0,
        behavior: "instant",
      });

      await this.changeCountrySearch();
    },

    isLoadingUser: async function () {
      const userId = this.$store?.state?.user?.user?.id;
      if (!this.isLoadingUser && userId) {
        await this.initPageService();
      }
    },
  },

  mounted: async function () {
    const authToken = this.$cookies.get('authToken');
    const userId = this.$store?.state?.user?.user?.id;
    if (!authToken || userId) {
      await this.initPageService();
    }
  },

  methods: {
    initPageService: async function () {
      if (Object.keys(this.service || {}).length > 0) {
        await this.getCountrysList();
        await this.initCurrentCountry();
        document.getElementById("scroll-container").scrollTo({
          top: 0,
          behavior: "instant",
        });
        await this.getPricesFavoriteCountries();
      }
    },

    initCurrentCountry: function () {
      const searchCountry = this.countrySearch;
      const cookiesSelectedCountryId = this.$cookies.get('selectedCountryId');

      let country = null;
      if (!!searchCountry) {
        country = this.countrysList.find((t) => Boolean(String(t.country_slug || '').toLowerCase() === String(searchCountry || '').toLowerCase()));
      }
      if (!country && !!String(cookiesSelectedCountryId)) {
        country = this.countrysList.find((t) => Boolean(String(t.country_external_id || '') === String(cookiesSelectedCountryId)));
      }
      if (!country) {
        country = this.countrysList?.[0];
      }
      // if (!country) {
      //   return
      // }

      this.currentCountry = country;
      this.isLoadingCurrentCountry = false;
    },
    getCountrysList: async function () {
      let user = this?.user?.id ? this?.user?.id : 'guest';
      if (!this?.user?.dynamicFields?.custom_price) {
        user = 'guest';
      }

      const countrys = await this.$api.agent.transport
        .get(`/country/get-prices/${ this.service?.id }?wholesale=0&user=${ user }`)
        .then(res => {
          return res.data;
        })
        .catch((err) => {
          return [];
        });

      this.countrysList = countrys;
    },
    changeCurrentCountry: async function (country) {
      this.currentCountry = country;

      this.$cookies.set('selectedCountryId', country?.country_external_id);
    },
    changeCountrySearch: async function () {
      const slugCountry = this.countrySearch;
      if (!slugCountry) {
        return
      }

      const languages = this.$store.state["languages-list"]?.languages || [];
      const language = languages.find((t) => Boolean(String(t.country_slug) === String(slugCountry)));
      if (!language) {
        return
      }

      const country = this.countrysList.find((t) => Boolean(String(t.country_external_id) === String(language.country_external_id)));
      if (!!country) {
        this.currentCountry = country;
      }
      if (!country) {
        const firstCountry = this.countrysList?.[0];
        if (!!firstCountry) {
          this.currentCountry = firstCountry;
          await this.getPriceFromCurrentCounrty(firstCountry);
        }
      }

      await this.getPriceFromCurrentCounrty(country);
    },
    buyNumberV2: async function (country, type) {
      this.isDisabledBuy = true;
      const countryExternalId = country?.external_id || country?.country_external_id;

      // Проверка авторизации пользователя
      const paymentTest = this.$cookies.get("payment-test");
      if (!this.isLoggedIn && paymentTest) {
        this.isDisabledBuy = false;
        await this.$router.push(this.localePath(`/profile/pay?service=${this?.service?.external_id}&country=${countryExternalId}`));
        return;
      }
      if (!this.isLoggedIn && !paymentTest) {
        this.isDisabledBuy = false;
        await this.$router.push(this.localePath("/registration?goback=1"));
        return null;
      }

      // Проверки на наличие средств для покупки
      const balance = await this.$api.agent.transport.get("sms-users/balance")
        .then((res) => {
          return res.data
        }).catch(() => {
          return 0
        });
      const numberPrice = Number.parseFloat(country?.price || '0');
      if (paymentTest && balance < numberPrice) {
        this.isDisabledBuy = false;
        await this.$router.push(this.localePath(`/profile/pay?service=${this?.service?.external_id}&country=${ countryExternalId }`));
        return;
      }
      if (!paymentTest && balance < numberPrice) {
        this.isDisabledBuy = false;
        await this.$router.push(this.localePath("/profile/pay"));
        return
      }

      // Процедура покупки номера
      this.yandexGoalCheck(Boolean(type === 'country') ? 'buy_service-country_attempt' : 'buy_service-main_attempt');
      this.yandexSendUserInfo({userId: this?.user?.id, userEmail: this?.user?.email});

      const countryId = countryExternalId;
      const serviceId = this.service?.external_id;
      const repsonse = await this.$api.agent.transport.get(`/sms-users/get-number/${countryId}/${serviceId}`)
        .then((res) => res?.data)
        .catch(err => {
          return {error: err?.response?.data?.[0]?.message || err?.response?.data?.message || this.$t('errors.err1')}
        });
      if (repsonse?.error) {
        this.isDisabledBuy = false;
        this.$toast.error(repsonse?.error);
        return
      }

      this.yandexGoalCheck(Boolean(type === 'country') ? 'buy_service-country_success' : 'buy_service-main_success');
      this.yandexSendUserID({userId: this?.user?.id});

      this.isDisabledBuy = false;
      await this.$store.commit("active-numbers/updateIndex");

      if (this.$route.path !== "/profile") {
        this.$router.push(this.localePath("/profile"));
      }
    },
    getPricesFavoriteCountries: async function () {
      let user = this?.user?.id ? this?.user?.id : 'guest';
      if (!this?.user?.dynamicFields?.custom_price) {
        user = 'guest';
      }
      if (user === 'guest') {
        return
      }

      let favoriteCountries = [...this.favoriteCountries];

      const slugService = this.$route?.params?.pathMatch || "";
      await Promise.all(favoriteCountries.map(async (country, index) => {
        const price = await this.$api.agent.transport.get(`/service/get-prices/${country.external_id}?user=${user}`).then((res) => {
          return (res.data || []).find((t) => t.service_slug === slugService)?.price || '-'
        }).catch(() => {
          return '-'
        });
        favoriteCountries[index]['price'] = price;
      }));

      this.favoriteCountries = favoriteCountries;
    },

    async getPriceFromCurrentCounrty(event) {
      // Получение активного кода страницы
      let user = this?.user?.id ? this?.user?.id : 'guest';
      if (!this?.user?.dynamicFields?.custom_price) {
        user = 'guest';
      }

      let currentCountryDefoultId = this.$defaultCountrySelection();

      let currentCountry = event?.country_external_id || this.currentCountry?.country_external_id || this.currentCountry?.external_id;
      currentCountry = Boolean(typeof currentCountry === "undefined" || currentCountry === null) ? String(currentCountryDefoultId) : String(currentCountry);
      currentCountry = +currentCountry;

      // Получение списка локальный услуг по выбранной стране
      const data = await this.$api.agent.transport
        .get(`service/get-prices/${currentCountry}?user=${user}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [];
        });

      this.localeServicesCountry = data;

    }
  },
};
